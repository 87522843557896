import React, { useState, useEffect, useRef, useCallback } from 'react';
import postService from '../../services/PostService';
import SeePost from './Seepost';
import filter from './filter.svg'

import InfluencerImageOrVideoPreview from '../../Components/Image/InfluencerImageOrVideoPreview';
import DeletePost from './DeletePost';


const ShowPost = ({ isOpen }) => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]); // State for filtered data
    const [cities, setCities] = useState([]); // State for unique cities
    const [statuses, setStatuses] = useState([]); // State for unique statuses
    const [currentPost, setCurrentPost] = useState(null);
    const [postInfo, setPostInfo] = useState({ index: 0, length: 0 });
    const [openSeePost, setOpenSeePost] = useState(false);
    const [currentPage, setCurrentPage] = useState(0); // Pagination
    const [soundState, setSoundState] = useState(false);
    const [cityFilter, setCityFilter] = useState('all'); // State for city filter
    const [statusFilter, setStatusFilter] = useState('all'); // State for status filter
    const [isDelete, setIsDelete] = useState(false)
    const [isMobileFilterOpen, setIsMobileFilterOpen] = useState(false)

    const pageSize = 12; // Number of posts per page
    const videoRefs = useRef([]);

    const handleOpenSeePost = (index) => {
        let map = document.getElementById("map");
        if (map) {
            map.style.zIndex = 0;
        }
        setCurrentPost(filteredData[index]);
        setPostInfo({ index, length: filteredData.length });
        setOpenSeePost(true);
    };

    const handleCloseSeePost = () => {
        setOpenSeePost(false);
    };

    const handlePostDeleteSuccess = (post_id) => {
        setOpenSeePost(false);
        setData([...data.slice(0, postInfo.index), ...data.slice(postInfo.index + 1)])
    }

    const handleOnNext = () => {
        setCurrentPost(filteredData[postInfo.index + 1]);
        setPostInfo({ index: postInfo.index + 1, length: filteredData.length });
    };

    const handleOnPrev = () => {
        setCurrentPost(filteredData[postInfo.index - 1]);
        setPostInfo({ index: postInfo.index - 1, length: filteredData.length });
    };

    useEffect(() => {
        setLoading(true);
        postService.getPostsByInfluencerId().then((data) => {
            setData(data);
            setFilteredData(data); // Initially, filtered data is the same as original data

            // Extract unique cities and statuses
            const uniqueCities = [...new Set(data.map(post => post.cityName))];
            const uniqueStatuses = [...new Set(data.map(post => post.status))];

            setCities(uniqueCities);
            setStatuses(uniqueStatuses);

            setLoading(false);
        });
    }, []);

    useEffect(() => {
        // Apply filtering based on city and status filters
        let filtered = data;
        console.log(cityFilter, statusFilter)

        if (cityFilter !== 'all') {
            filtered = filtered.filter(post => post.cityName === cityFilter);
        }

        if (statusFilter !== 'all') {
            filtered = filtered.filter(post => post.status === statusFilter);
        }

        setFilteredData(filtered);
        setCurrentPage(0); // Reset pagination when filters change
    }, [cityFilter, statusFilter, data]);

    const totalPages = Math.ceil(filteredData.length / pageSize);
    const paginatedData = filteredData.slice(currentPage * pageSize, (currentPage + 1) * pageSize);

    return (
        <div className={`md:flex justify-center items-center ${loading && 'h-64'} w-full mb-4`}>
            {loading ? (
                <div className="animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-blue-500"></div>
            ) : (
                <div className="w-full">
                    {isMobileFilterOpen && (
                        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                            <div className="bg-white w-[80%] max-w-[300px] rounded-lg p-6 relative text-center">
                                {/* Close Button - Positioned Outside the Box */}
                                <button
                                    className="absolute top-[-15px] right-[-15px] bg-gray-300 text-gray-700 font-bold text-2xl rounded-full w-10 h-10 flex items-center justify-center hover:bg-gray-400"
                                    onClick={() => setIsMobileFilterOpen(false)}
                                >
                                    &times;
                                </button>

                                {/* Status Filter */}
                                <select
                                    id="status-filter"
                                    className="border border-gray-300 p-3 rounded-[20px] w-full mb-4"
                                    value={statusFilter}
                                    onChange={(e) => setStatusFilter(e.target.value)}
                                >
                                    <option value="all">Status</option>
                                    {statuses.map((status, index) => (
                                        <option key={index} value={status}>{status}</option>
                                    ))}
                                </select>

                                {/* City Filter */}
                                <select
                                    id="city-filter"
                                    className="border border-gray-300 p-3 rounded-[20px] w-full mb-4"
                                    value={cityFilter}
                                    onChange={(e) => setCityFilter(e.target.value)}
                                >
                                    <option value="all">City</option>
                                    {cities.map((city, index) => (
                                        <option key={index} value={city}>{city}</option>
                                    ))}
                                </select>

                                {/* Reset Filters Button */}
                                <p
                                    className="text-bold p-2 rounded font-bold cursor-pointer"
                                    onClick={() => {
                                        setCityFilter('all');
                                        setStatusFilter('all');
                                    }}
                                >
                                    Reset Filters
                                </p>
                            </div>
                        </div>
                    )}




                    <div className="flex items-center md:mb-4 space-x-4 p-2 md:p-0 md:hidden" onClick={() => setIsMobileFilterOpen(!isMobileFilterOpen)}>
                        {/* Filter Icon and Label */}
                        <img src={filter} alt="Filter" className="w-10 h-10" />

                        <span className="md:hidden text-gray-700">Apply Filters:</span></div>
                    {/* Filter UI */}
                    <div className="hidden md:flex flex items-center mb-4 space-x-4">
                        {/* Filter Icon and Label */}
                        <img src={filter} alt="Filter" className="w-10 h-10" />
                        <span className=" text-gray-700">Filter By:</span>


                        {/* Status Dropdown */}
                        {/* Status Dropdown */}
                        <select
                            id="status-filter"
                            className=" border border-gray-300 p-[8px] rounded-[20px] w-[130px] text-center p-2"
                            value={statusFilter}
                            onChange={(e) => setStatusFilter(e.target.value)}
                        >
                            <option value="all">Status</option>
                            {statuses.map((status, index) => (
                                <option key={index} value={status}>{status}</option>
                            ))}
                        </select>

                        {/* City Dropdown */}
                        <select
                            id="city-filter"
                            className=" border border-gray-300 p-[8px] rounded-[20px] w-[130px] text-center p-2"
                            value={cityFilter}
                            onChange={(e) => setCityFilter(e.target.value)}
                        >
                            <option value="all">City</option>
                            {cities.map((city, index) => (
                                <option key={index} value={city}>{city}</option>
                            ))}
                        </select>


                        {/* Reset Filter Button */}
                        <p
                            className="hidden md:block p-2 rounded font-bold cursor-pointer"
                            onClick={() => {
                                setCityFilter('all');
                                setStatusFilter('all');
                            }}
                        >
                            Reset Filters
                        </p>
                    </div>



                    {/* Post Grid */}
                    <div className="flex flex-wrap bg-white">
                        {paginatedData.map((post, index) => (
                            <div key={index} className="w-full md:w-1/3">
                                <div key={`index`} className={`relative w-full md:aspect-square pointer-cursor ${index % 4 < 3 && index !== data.length - 1 && 'md:border-r border-black'} ${index > 3 && 'md:border-t border-black'} md:hidden`}>
                                    <InfluencerImageOrVideoPreview ref={el => videoRefs.current[index] = el}
                                        src={post.type === "image" ? post.fileUrl.medium.imageUrl : post.fileUrl.large.imageUrl}
                                        type={post.type} username={post.username} instagramUrl={post.instagramUrl}
                                        influencerPosts={true}
                                        setIsDelete={setIsDelete}
                                        post={post}
                                        setCurrentPost={setCurrentPost}
                                    />
                                    <div class="md:hidden absolute bottom-0 w-full h-[51px] bg-white z-100  border border-[#c2c0c0]">
                                        <div class="flex items-center justify-between w-full px-2">
                                            <div class="overflow-hidden">
                                                <p class="font-semibold text-[16px] truncate">{post.markerName}</p>
                                                <p class="text-sm text-gray-600 truncate">{post.cityName}</p>
                                            </div>
                                            <div class={`w-2 h-2 ml-2 rounded-full ${post.status === 'pending' ? 'bg-yellow-500' : post.status === 'approved' ? 'bg-green-500' : 'bg-red-500'}`}></div>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    className={`relative w-full md:aspect-square pointer-cursor ${index % 3 < 2 && index !== data.length - 1 && 'md:border-r-4 border-black'} ${index > 2 && 'md:border-t-4 border-black'} hidden md:block`}
                                    onClick={() => handleOpenSeePost(index)}
                                >
                                    <InfluencerImageOrVideoPreview
                                        src={post.type === "image" ? post.fileUrl.medium.imageUrl : post.fileUrl.large.imageUrl}
                                        type={post.type}
                                        username={post.username}
                                        instagramUrl={post.instagramUrl}
                                        status={post.status}
                                        cityName={post.cityName}
                                        markerName={post.markerName}
                                        influencerPosts={true}
                                    />

                                </div>

                            </div>
                        ))}
                    </div>
                </div>
            )}
            <SeePost
                currentPost={currentPost}
                onClose={handleCloseSeePost}
                isOpen={openSeePost}
                postInfo={postInfo}
                onNext={handleOnNext}
                onPrev={handleOnPrev}
                handlePostDeleteSuccess={handlePostDeleteSuccess}
            />
            <DeletePost
                isOpen={isDelete}
                onDeleteSuccess={handlePostDeleteSuccess}
                onClose={() => { setIsDelete(false) }}
                post={currentPost}
            />
        </div>
    );
};

export default ShowPost;
