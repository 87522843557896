import { FaSquarePen, FaTrash, FaArrowLeft, FaArrowRight, FaChevronLeft, FaChevronRight } from 'react-icons/fa6';
import { FaChevronCircleLeft, FaTimes } from 'react-icons/fa'
import './SeePost.css';
import insta from './insta.svg';
import ShowPost from './ShowPost';
import EditPost from './EditPost';
import { useState } from 'react';
import DeletePost from './DeletePost';

const SeePost = ({ currentPost, onClose, onPrev, onNext, isOpen, postInfo, handlePostEditSuccess, handlePostDeleteSuccess }) => {
    const [isEdit, setIsEdit] = useState(false)
    const [isDelete, setIsDelete] = useState(false)

    const handlePostEdit = () => {
        setIsEdit(true)
    }
    const handlePostDelete = () => {
        setIsDelete(true)
    }
    const handleCloseEdit = () => {
        setIsEdit(false)
    }

    const handleCloseDelete = () => {
        setIsDelete(false)
    }
    const handleSuccessfulEdit = (post) => {
        setIsEdit(false)
        handlePostEditSuccess(post)
    }

    const handleSuccessfulDelete = (post_id) => {
        setIsDelete(false)
        handlePostDeleteSuccess(post_id)
    }

    if (!isOpen) return null;
    const handleVisitInstagram = (link) => {
        window.open(link, '_blank'); // Replace 'https://www.example.com' with your external link
    };
    return (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-70 w-full h-full flex items-center justify-center z-10">
            <div className="relative flex pointer-cursor h-3/4 bg-white">
                {currentPost.type == 'image' ? (
                    <img src={currentPost.fileUrl.large.imageUrl} className="w-auto h-full object-cover max-w-[50vw] min-w-[300px]" />
                ) : (
                    <video key={currentPost.fileUrl.large.imageUrl} controls loop autoPlay className="w-full h-full object-cover max-w-[50vw] min-w-[300px]">
                        <source src={currentPost.fileUrl.large.imageUrl} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                )}


            </div>

            <div className="relative min-w-[300px] h-3/4 bg-white border-l-2 border-gray-400">
                <div className="relative h-full p-4">
                    <p className="ml-2 flex items-center text-[20px] font-semibold">
                        {currentPost.markerName}
                    </p>
                    <p className="ml-2 mt-1 text-[18px] font-normal text-[#A2A2A2]">
                        {currentPost.cityName}
                    </p>
                    <p className="ml-2 mt-1 text-[16px] font-normal text-[#A2A2A2]">
                        status - <span className={
                            currentPost.status === "pending" ? 'text-yellow-500' :
                                currentPost.status === "approved" ? 'text-green-500' :
                                    currentPost.status === "rejected" ? 'text-red-500' : ''
                        }>
                            {currentPost.status}
                        </span>
                    </p>


                </div>
                <div className="absolute bottom-0 w-full">
                    <div className="flex justify-center space-x-4 p-4">
                        {/* <button className="action-button flex items-center" onClick={handlePostEdit}>
                            <FaSquarePen size={18} style={{ marginRight: '2px' }} />
                            Edit Post
                        </button> */}
                        <button className="action-button flex items-center" onClick={handlePostDelete}>
                            <FaTrash size={18} style={{ marginRight: '2px' }} />
                            Delete Post
                        </button>
                    </div>
                </div>
            </div>

            <button
                className="absolute top-4 right-4 text-white text-2xl p-2"
                onClick={onClose}>
                <FaTimes />
            </button>

            <button
                className={`absolute left-4 top-1/2 transform -translate-y-1/2 text-black text-2xl p-2 bg-white rounded-full ${postInfo.index <= 0 && 'pointer-events-none opacity-50'}`}
                onClick={onPrev}>
                <FaChevronLeft />
            </button>

            <button
                className={`absolute right-4 top-1/2 transform -translate-y-1/2 text-black text-2xl p-2 bg-white rounded-full ${postInfo.index == postInfo.length - 1 && 'pointer-events-none opacity-50'}`}
                onClick={onNext}>
                <FaChevronRight />
            </button>

            <EditPost
                isOpen={isEdit}
                post={currentPost}
                onCancel={handleCloseEdit}
                onSuccessEdit={handleSuccessfulEdit}
            />

            <DeletePost
                isOpen={isDelete}
                onClose={handleCloseDelete}
                post={currentPost}
                onDeleteSuccess={handleSuccessfulDelete}
            />
        </div>
    );
}

export default SeePost;
