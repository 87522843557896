import React, { useState, useEffect, useRef, useCallback } from 'react';
import { FaImage, FaVideo } from 'react-icons/fa';
import postService from '../../services/PostService';
import SeePost from './../Post/Seepost';
import ImageOrVideoPreview from '../../components/Image/ImageOrVideoPreview';
import InfluencerImageOrVideoPreview from '../../components/Image/InfluencerImageOrVideoPreview';

const Post = ({ isOpen }) => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [currentPost, setCurrentPost] = useState(null);
    const [postInfo, setPostInfo] = useState({ index: 0, length: 0 });
    const [openSeePost, setOpenSeePost] = useState(false);
    const [currentPage, setCurrentPage] = useState(0); // New state for pagination
    const [soundState,setSoundState]=useState(false)

    const pageSize = 12; // Number of posts per page
    const videoRefs = useRef([]);

    const handleOpenSeePost = (index) => {
        let map = document.getElementById("map");
        if (map) {
            map.style.zIndex = 0;
        }
        setCurrentPost(data[index]);
        setPostInfo({ index, length: data.length });
        setOpenSeePost(true);
    };

    const handleCloseSeePost = () => {
        setOpenSeePost(false);
    };

    const handleOnNext = () => {
        setCurrentPost(data[postInfo.index + 1]);
        setPostInfo({ index: postInfo.index + 1, length: data.length });
    };
    const handleOnPrev = () => {
        setCurrentPost(data[postInfo.index - 1]);
        setPostInfo({ index: postInfo.index - 1, length: data.length });
    };

    useEffect(() => {
        setLoading(true);
        postService.getPostsByInfluencers().then((data) => {
            setData(data);
            setLoading(false);
        });
    }, []);

    const handleIntersection = useCallback((entries) => {
        let mostVisibleEntry = null;
        let maxVisibleArea = 0;
        entries.forEach(entry => {
            const visibleArea = entry.intersectionRect.width * entry.intersectionRect.height;
            if (visibleArea > maxVisibleArea) {
                maxVisibleArea = visibleArea;
                mostVisibleEntry = entry;
            }
        });

        if (mostVisibleEntry) {
            const index = videoRefs.current.findIndex(video => video.video === mostVisibleEntry.target);
            if (index !== -1) {
                videoRefs.current.forEach((video, i) => {
                    if(video.type === "image"){
                        return;
                    }
                    video = video.video;
                    if (i === index) {
                        video.play();
                        video.muted = soundState;
                    } else {
                        video.pause();
                        //video.muted = true;
                    }
                });
            }
        }
    }, []);

    useEffect(() => {
        const observer = new IntersectionObserver(handleIntersection, {
            root: null,
            threshold: 0.85,
        });
        try {
            videoRefs.current.forEach(video => observer.observe(video.video));
        } catch { }
        return () => {
            try {
                videoRefs.current.forEach(video => observer.unobserve(video.video));
            } catch { }
        };
    }, [data, handleIntersection]);

    const handleVideoPlay = (index) => {
        videoRefs.current.forEach((video, i) => {
            if(video.type === "image"){
                return;
            }
            video = video.video;
            if (i !== index) {
                video.pause();
                //video.muted = true;
            }
            else {
                video.muted = soundState;
            }
        });
    };

    useEffect(() => {
        videoRefs.current.forEach((video, index) => {
            if(video.type === "image"){
                return;
            }
            if (video.video) {
                video.video.addEventListener('play', () => handleVideoPlay(index));
            }
        });

        return () => {
            videoRefs.current.forEach((video, index) => {
                if(video.type === "image"){
                    return;
                }
                if (video.video) {
                    video.video.removeEventListener('play', () => handleVideoPlay(index));
                }
            });
        };
    }, [data]);

    useEffect(() => {
        videoRefs.current.forEach((video, index) => {
           
            if(video.type === "image"){
                return;
            }
            if (video.video) {
                video.video.addEventListener('volumechange', () => {
                    console.log("video.video",video.video.muted,soundState,video.video)
                    setSoundState(video.video.muted)
                });
            }
        });

        return () => {
            videoRefs.current.forEach((video, index) => {
                if(video.type === "image"){
                    return;
                }
                if (video.video) {
                    video.video.removeEventListener('volumechange',() => {
                        setSoundState(video.video.muted)
                    });
                }
            });
        };
    }, [data]);

    if (!isOpen) {
        return null;
    }

    const totalPages = Math.ceil(data.length / pageSize);
    const paginatedData = data.slice(currentPage * pageSize, (currentPage + 1) * pageSize);

    return (
        <div className={`md:flex justify-center items-center ${loading && 'h-64'} bg-gray-100 w-full mb-4`}>
            {loading ? (
                <div className="animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-blue-500"></div>
            ) : (
                <div className='w-full flex flex-wrap bg-white'>
                    {paginatedData.map((post, index) => (
                        <div key={index} className='w-full md:w-1/3 border-b-2 border-white'>
                            <div className={`relative w-full md:aspect-square pointer-cursor ${index % 4 < 3 && index !== data.length - 1 && 'md:border-r border-black'} ${index > 3 && 'md:border-t border-black'} md:hidden`}>
                                <InfluencerImageOrVideoPreview ref={el => videoRefs.current[index] = {type:post.type, video:el}} src={post.type === "image" ? post.fileUrl.medium.imageUrl : post.fileUrl.large.imageUrl} type={post.type} username={post.username} instagramUrl={post.instagramUrl} />
                            </div>
                            <div key={`desk-${index}`} className={`relative w-full md:aspect-square pointer-cursor ${index % 4 < 3 && index !== data.length - 1 && 'md:border-r border-black'} ${index > 3 && 'md:border-t border-black'} hidden md:block`} onClick={() => { handleOpenSeePost(index) }}>
                                <InfluencerImageOrVideoPreview src={post.type === "image" ? post.fileUrl.medium.imageUrl : post.fileUrl.large.imageUrl} type={post.type} username={post.username} instagramUrl={post.instagramUrl} />
                            </div>
                        </div>
                    ))}
                </div>
            )}
            <SeePost
                currentPost={currentPost}
                onClose={handleCloseSeePost}
                isOpen={openSeePost}
                postInfo={postInfo}
                onNext={handleOnNext}
                onPrev={handleOnPrev}
            />
           
        </div>
    );
};

export default Post;
