import React, { useState, useEffect, forwardRef } from 'react';
import { FaImage, FaVideo } from 'react-icons/fa';
import insta from './insta.svg';
import trash from './trash.svg'

const InfluencerImageOrVideoPreview = forwardRef(({ post,setCurrentPost,src, type, username, instagramUrl, status, cityName, markerName, influencerPosts,setIsDelete }, ref) => {
  const [loaded, setLoaded] = useState(false);

  const handleVisitInstagram = () => {
    if (instagramUrl) {
      window.open(instagramUrl, '_blank');
    }
  };

  useEffect(() => {
    const handleLoad = () => setLoaded(true);

    if (type === 'image') {
      const img = new Image();
      img.src = src;
      img.onload = handleLoad;
    } else if (type === 'video') {
      const video = document.createElement('video');
      video.src = src;
      video.onloadeddata = handleLoad;
    }
  }, [src, type]);

  return (
    <div className={`relative flex items-center w-full ${loaded ? 'h-full max-h-[75vh] min-h-[60vh] md:aspect-square md:h-auto md:min-h-0' : 'aspect-square'} bg-[#28231D]`}>
      {!loaded && (
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-b-4 border-gray-800"></div>
        </div>
      )}
      {type === 'image' ? (
        <>
          {influencerPosts ? <img src={trash} onClick={()=>{setIsDelete(true);setCurrentPost(post);}}  className="cursor-pointer md:hidden absolute top-2 right-2 m-1 text-white p-2 rounded-full bg-[#D9D9D9]"  size={24} /> : <FaImage className="absolute top-2 right-2 m-1 text-white p-1" size={24} />}

          <img
            src={src}
            ref={ref}
            onLoad={() => setLoaded(true)}
            alt="Media content"
            className={`w-full h-full object-cover ${loaded ? 'block' : 'hidden'}`}
          />
          <div className='absolute top-2 left-2 m-1 text-white p-1 cursor-pointer' onClick={handleVisitInstagram}>
            <p className='flex items-center'>
              <img src={insta} width={24} height={24} className='mr-1' />
              {username}
            </p>
          </div>
        </>
      ) : (
        <>
          {influencerPosts ? <img src={trash} onClick={()=>{setIsDelete(true);setCurrentPost(post);}} className="cursor-pointer md:hidden absolute top-2 right-2 m-1 text-white p-2 rounded-full bg-[#D9D9D9]" size={24} /> : <FaVideo className="absolute top-2 right-2 m-1 text-white p-1" size={24} />}

          <video
            ref={ref}
            src={src}
            controls
            loop
            onLoadedData={() => setLoaded(true)}
            className={`w-full h-full max-h-[75vh] object-cover ${loaded ? 'block' : 'hidden'} md:hidden`}
          />
          <video
            src={src}
            onLoadedData={() => setLoaded(true)}
            className={`w-full h-full object-cover ${loaded ? 'block' : 'hidden'} hidden md:block`}
          />
          <div className='absolute top-2 left-2 m-1 text-white p-1 cursor-pointer' onClick={handleVisitInstagram}>
            <p className='flex items-center'>
              <img src={insta} width={24} height={24} className='mr-1' />
              {username}
            </p>
          </div>
        </>
      )}
      {influencerPosts && <div class="hidden md:block absolute bottom-0 w-full h-[51px] bg-white z-10 rounded-t-lg border border-[#c2c0c0]">
        <div class="flex items-center justify-between w-full px-2">
          <div class="overflow-hidden">
            <p class="font-semibold text-[16px] truncate">{markerName}</p>
            <p class="text-sm text-gray-600 truncate">{cityName}</p>
          </div>
          <div class={`w-2 h-2 ml-2 rounded-full ${status === 'pending' ? 'bg-yellow-500' : status === 'approved' ? 'bg-green-500' : 'bg-red-500'}`}></div>
        </div>
      </div>}


    </div>
  );
});

export default InfluencerImageOrVideoPreview;
